export const IS_MAINNET = Boolean(process.env.REACT_APP_IS_MAINNET !== 'false');  // If REACT_APP_IS_MAINNET is unset, set it to true by default
export const TESTNET_LAUNCHPAD_NAME = 'PulseChain Testnet v4';
export const EL_TESTNET_NAME = 'TestnetV4';

// private vars (or derived from)
export const PORTIS_DAPP_ID = process.env.REACT_APP_PORTIS_DAPP_ID || '';
export const INFURA_PROJECT_ID = process.env.REACT_APP_INFURA_PROJECT_ID || '';
export const ENABLE_RPC_FEATURES = Boolean(INFURA_PROJECT_ID && INFURA_PROJECT_ID !== '');
export const RPC_URL = IS_MAINNET ? 'https://rpc.pulsechain.com' : 'https://rpc.v4.testnet.pulsechain.com';
export const INFURA_URL = RPC_URL;
export const RPC_CHAIN_ID = IS_MAINNET ? '943' : '943';

// public
export const GENESIS_FORK_VERSION_HEX = IS_MAINNET ? '00000943' : '00000943';
export const NETWORK_NAME = IS_MAINNET ? 'PulseChain Mainnet' : TESTNET_LAUNCHPAD_NAME;
export const TICKER_NAME = IS_MAINNET ? 'PLS' : 'TPLS';
export const ETHERSCAN_URL = IS_MAINNET ? 'https://scan.pulsechain.com/tx' : `https://scan.v4.testnet.pulsechain.com/tx`;
export const BEACONSCAN_URL = IS_MAINNET ? 'https://beacon.pulsechain.com/validator' : `https://beacon.v4.testnet.pulsechain.com/validator`;
export const BEACONCHAIN_URL = IS_MAINNET ? 'https://go-hex-com-ehex-price-data.a4056c9392ff6eb5fa7904d106b55a.workers.dev' : 'https://go-hex-com-ehex-price-data.a4056c9392ff6eb5fa7904d106b55a.workers.dev/testnet-v4';
export const FORTMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY || 'pk_test_D113D979E0D3508F';
export const CONTRACT_ADDRESS = IS_MAINNET ? '0x3693693693693693693693693693693693693693' : '0x3693693693693693693693693693693693693693';
export const MIN_DEPOSIT_CLI_VERSION = process.env.REACT_APP_MIN_DEPOSIT_CLI_VERSION || '1.0.0';
export const LIGHTHOUSE_INSTALLATION_URL = 'https://gitlab.com/pulsechaincom/lighthouse-pulse';
export const NIMBUS_INSTALLATION_URL = 'https://nimbus.guide/intro.html';
export const PRYSM_INSTALLATION_URL = 'https://gitlab.com/pulsechaincom/prysm-pulse';
export const TEKU_INSTALLATION_URL = 'https://docs.teku.pegasys.tech/en/latest/HowTo/Get-Started/Build-From-Source/';
export const MAINNET_LAUNCHPAD_URL = 'https://launchpad.pulsechain.com/'
export const TESTNET_LAUNCHPAD_URL = 'https://launchpad.v4.testnet.pulsechain.com/'
export const FAUCET_URL = process.env.REACT_APP_FAUCET_URL || 'https://faucet.v4.testnet.pulsechain.com'
export const TUTORIAL_URL = process.env.REACT_APP_TUTORIAL_URL || null;

if (process.env.REACT_APP_ETH_REQUIREMENT && Number.isNaN(Number(process.env.REACT_APP_ETH_REQUIREMENT))) {
    throw new Error("REACT_APP_ETH_REQUIREMENT must be of type: number")
}
export const ETH_REQUIREMENT = process.env.REACT_APP_ETH_REQUIREMENT || 524288;

// ETH_DEPOSIT_OFFSET is added to the balance of the deposit contract to account for testnet deposit-contracts that allow some number of free deposit
if (process.env.REACT_APP_ETH_DEPOSIT_OFFSET && Number.isNaN(Number(process.env.REACT_APP_ETH_DEPOSIT_OFFSET))) {
    throw new Error("REACT_APP_ETH_DEPOSIT_OFFSET must be of type: number")
}
export const ETH_DEPOSIT_OFFSET = Number(process.env.REACT_APP_ETH_DEPOSIT_OFFSET) * Number(!IS_MAINNET) || 0;

export const GENESIS_FORK_VERSION = Buffer.from(GENESIS_FORK_VERSION_HEX, 'hex');


if (process.env.REACT_APP_PRICE_PER_VALIDATOR && Number.isNaN(Number(process.env.REACT_APP_PRICE_PER_VALIDATOR))) {
    throw new Error("REACT_APP_PRICE_PER_VALIDATOR must be of type: number")
}
export const PRICE_PER_VALIDATOR = parseInt(process.env.REACT_APP_PRICE_PER_VALIDATOR || '32000000', 10);

if (process.env.REACT_APP_EJECTION_PRICE && Number.isNaN(Number(process.env.REACT_APP_EJECTION_PRICE))) {
    throw new Error("REACT_APP_EJECTION_PRICE must be of type: number")
}
export const EJECTION_PRICE = process.env.REACT_APP_EJECTION_PRICE || 16000000;

// BLS signature verification variables
export const ETHER_TO_GWEI = 1e9;
export const MIN_DEPOSIT_AMOUNT = 1 * ETHER_TO_GWEI;
export const DOMAIN_DEPOSIT = Buffer.from('03000000', 'hex');
export const EMPTY_ROOT = Buffer.from('0000000000000000000000000000000000000000000000000000000000000000', 'hex');

// Boolean to translate CLI command flags, or keep in English
export const TRANSLATE_CLI_FLAGS = process.env.REACT_APP_TRANSLATE_CLI_FLAGS === 'true';
