import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Box, DropButton } from 'grommet';
import { Menu, FormDown } from 'grommet-icons';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import PulseChainLogo from '../static/pulsechain-logo.png';
import { web3ReactInterface } from '../pages/ConnectWallet';
import {
  AllowedNetworks,
  NetworkChainId,
} from '../pages/ConnectWallet/web3Utils';
import { Dot } from './Dot';
import { Link } from './Link';
import { Text } from './Text';
import { routesEnum } from '../Routes';
import { Heading } from './Heading';
import {
  IS_MAINNET,
  NETWORK_NAME,
  MAINNET_LAUNCHPAD_URL,
  TESTNET_LAUNCHPAD_NAME,
  TESTNET_LAUNCHPAD_URL,
} from '../utils/envVars';
import { trimString } from '../utils/trimString';
import useIntlNetworkName from '../hooks/useIntlNetworkName';
import useMobileCheck from '../hooks/useMobileCheck';

const BlackBox = styled(Box)`
  background-color: ${(p: { theme: any }) => p.theme.gray.darkish};
`;

const TopBackground = styled(Box)`
  background-color: ${(p: { theme: any }) => p.theme.gray.darkish};
  border-bottom: 1px solid rgb(50, 50, 52);
`;

const BottomBackground = styled.div`
  background-color: #282828;
  border-top: 1px solid black;
  display: flex;
  justify-content: center;
`;

const Logo = styled.img`
  height: 32px;
  width: 148px;
`;

const NetworkText = styled.div`
  padding: 5px 8px;
  border: 1px solid;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  &:hover {
    border-radius: 4px;
    box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.15);
    background-color: ${(p: { theme: any }) => p.theme.gray.medium};
    transition: transform 0.1s;
    transform: scale(1.02);
  }
`;

const NavBarLinks = styled.div`
  display: flex;
  @media only screen and (max-width: 1080px) {
    .secondary-link {
      display: none;
    }
  }
`;

const ClientsDropdown = styled(DropButton)`
  background-color: ${(p: { theme: any }) => p.theme.gray.medium};
  padding: 12px 8px;
  font-weight: 300;
  display: flex;
  align-items: center;
  border: none;
  :hover {
    border: none;
    box-shadow: none;
  }
`;

const ValidatorDropdown = styled(DropButton)`
  background-color: ${(p: { theme: any }) => p.theme.gray.darkish};
  padding: 12px 8px;
  font-weight: 300;
  display: flex;
  align-items: center;
  border: none;
  :hover {
    border: none;
    box-shadow: none;
  }
`;

const DotDropdown = styled(DropButton)`
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0;
  :hover {
    transition: transform 0.2s;
    transform: scale(1.1);
  }
`;

const DropdownLink = styled(Link)`
  color: ${(p: { theme: any }) => p.theme.gray.light};
  :hover {
    text-decoration: underline;
  }
`;

const Card = styled.div`
  background-color: ${(p: { theme: any }) => p.theme.gray.medium};
`;

const NetworkInfo = styled.div`
  background: ${p => p.theme.gray.light};
  padding: 32px;
`;

const NavLinksRight = styled.div`
  display: flex;
  align-items: center;
`;

const UpperBarLinkText = styled(Heading as any)`
  :not(.no-padding) {
    padding: 0 12px;
    white-space: nowrap;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: ${(p: { active?: boolean }) =>
    p.active ? 'white' : 'rgb(204, 204, 204)'};
  font-weight: ${(p: { active?: boolean }) => (p.active ? '600' : '400')};
`;

const BarLinkText = styled(Heading as any)`
  :not(.no-padding) {
    padding: 0 12px;
    white-space: nowrap;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: ${(p: { active?: boolean }) =>
    p.active ? 'white' : 'rgb(204, 204, 204)'};
  font-weight: ${(p: { active?: boolean }) => (p.active ? '600' : '400')};
  :after {
    content: '';
    position: relative;
    bottom: -12px;
    height: 4px;
    width: 100%;
    background-color: rgb(0, 204, 68);
    border-radius: 2px 2px 0px 0px;
    visibility: ${(p: { active?: boolean }) =>
      p.active ? 'visible' : 'hidden'};
  }
`;

const _AppBar = ({ location }: RouteComponentProps) => {
  const {
    active: walletConnected,
    account,
    chainId,
  }: web3ReactInterface = useWeb3React<Web3Provider>();
  const { executionLayerName, consensusLayerName } = useIntlNetworkName();
  const oppositeNetwork = IS_MAINNET ? (
    <FormattedMessage
      defaultMessage="{TESTNET_LAUNCHPAD_NAME} testnet"
      values={{ TESTNET_LAUNCHPAD_NAME }}
    />
  ) : (
    <FormattedMessage defaultMessage="Mainnet" />
  );

  let network;
  let networkAllowed = false;

  if (chainId) {
    network = NetworkChainId[chainId];
    networkAllowed = Object.values(AllowedNetworks).includes(network);
  }

  const { locale } = useIntl();
  const pathname: string = React.useMemo(
    () => location.pathname.replace(`/${locale}`, ''),
    [location.pathname, locale]
  );

  const isDropdownPage = React.useMemo(
    () =>
      pathname === routesEnum.lighthouse ||
      pathname === routesEnum.nimbus ||
      pathname === routesEnum.prysm ||
      pathname === routesEnum.teku,
    [pathname]
  );

  const mobile = useMobileCheck('1080px');
  const switchLaunchpadUrl = IS_MAINNET
    ? TESTNET_LAUNCHPAD_URL
    : MAINNET_LAUNCHPAD_URL;

  return (
    <React.Fragment>
      <TopBackground
        tag="header"
        direction="row"
        align="center"
        justify="between"
        pad={{ left: 'medium', right: 'small', vertical: 'small' }}
        elevation="medium"
        style={{ zIndex: 1 }}
      >
        <NavBarLinks>
          <Link to={routesEnum.landingPage} className="mr30">
            <Logo src={PulseChainLogo} alt="pls-logo" />
          </Link>

          <Link
            to="https://app.v4.testnet.pulsex.com/"
            className="mx10 secondary-link"
          >
            <BarLinkText level={4} margin="none" className="bar-link-text">
              <FormattedMessage defaultMessage="PulseX" />
            </BarLinkText>
          </Link>
          <Link to={routesEnum.landingPage} className="mx10 secondary-link">
            <UpperBarLinkText
              level={4}
              margin="none"
              className="bar-link-text"
              active={true}
            >
              <FormattedMessage defaultMessage="PulseChain" />
            </UpperBarLinkText>
          </Link>
          <Link to="https://pulseramp.com/" className="mx10 secondary-link">
            <BarLinkText level={4} margin="none" className="bar-link-text">
              <FormattedMessage defaultMessage="PulseBridge" />
            </BarLinkText>
          </Link>
          <Link
            to="https://pulsechain-sacrifice-checker.vercel.app/"
            className="mx10 secondary-link"
          >
            <BarLinkText level={4} margin="none" className="bar-link-text">
              <FormattedMessage defaultMessage="Sacrifice Checker" />
            </BarLinkText>
          </Link>
        </NavBarLinks>
        <NavLinksRight>
          {mobile && (
            <ValidatorDropdown
              className="secondary-link"
              label={<Menu color="white" />}
              dropAlign={{ top: 'bottom', right: 'right' }}
              dropContent={
                <Card>
                  <NetworkInfo>
                    {walletConnected && (
                      <Box className="flex flex-row mb20">
                        <Dot success={networkAllowed} error={!networkAllowed} />
                        <Text size="small" className="ml10" color="greenLight">
                          {trimString(account as string, 10)}
                        </Text>
                      </Box>
                    )}
                    <span>
                      <FormattedMessage defaultMessage="Launchpad network:" />{' '}
                      <b>{consensusLayerName}</b>
                    </span>
                    <Link primary to={switchLaunchpadUrl}>
                      <FormattedMessage
                        defaultMessage="Switch to {oppositeNetwork} launchpad"
                        values={{ oppositeNetwork }}
                      />
                    </Link>
                    <Text className="mt20">
                      <em>
                        <FormattedMessage defaultMessage="Visit this site on desktop to become a validator." />
                      </em>
                    </Text>
                  </NetworkInfo>
                  <BlackBox pad="large" className="mt0">
                    <DropdownLink to={routesEnum.FaqPage}>
                      <FormattedMessage defaultMessage="FAQ" />
                    </DropdownLink>
                    <DropdownLink to={routesEnum.checklistPage}>
                      <FormattedMessage defaultMessage="Staker checklist" />
                    </DropdownLink>
                    <Text className="my20">
                      <b>
                        <FormattedMessage defaultMessage="Execution clients" />
                      </b>
                    </Text>
                    <DropdownLink to={routesEnum.erigon}>
                      Erigon-Pulse
                    </DropdownLink>
                    <DropdownLink to={routesEnum.geth}>Go-Pulse</DropdownLink>
                    <Text className="my20">
                      <b>
                        <FormattedMessage defaultMessage="Consensus clients" />
                      </b>
                    </Text>
                    <DropdownLink to={routesEnum.lighthouse}>
                      Lighthouse-Pulse
                    </DropdownLink>
                    <DropdownLink to={routesEnum.prysm}>
                      Prysm-Pulse
                    </DropdownLink>
                  </BlackBox>
                </Card>
              }
            />
          )}
          {!mobile && (
            <ValidatorDropdown
              className="secondary-link"
              label={
                <NetworkText>
                  {NETWORK_NAME}
                  <FormDown />
                </NetworkText>
              }
              dropAlign={{ top: 'bottom', right: 'right' }}
              dropContent={
                <Card>
                  <Box pad="small" className="mt0">
                    {!IS_MAINNET && (
                      <Text className="mb10">
                        <FormattedMessage defaultMessage="This is a test network ⚠️" />
                      </Text>
                    )}
                    {false && (
                      <DropdownLink to={switchLaunchpadUrl}>
                        <FormattedMessage
                          defaultMessage="Switch to {oppositeNetwork} launchpad"
                          values={{ oppositeNetwork }}
                        />
                      </DropdownLink>
                    )}
                  </Box>
                </Card>
              }
            />
          )}
          {!mobile && walletConnected && (
            <Box className="flex flex-row mr20">
              {networkAllowed && (
                <DotDropdown
                  className="secondary-link"
                  label={
                    <Dot success={networkAllowed} error={!networkAllowed} />
                  }
                  dropAlign={{ top: 'bottom', right: 'right' }}
                  dropContent={
                    <Box pad="small">
                      <Text>
                        <FormattedMessage defaultMessage="Your wallet is connected to the right network!" />
                      </Text>
                    </Box>
                  }
                />
              )}
              {!networkAllowed && (
                <DotDropdown
                  className="secondary-link"
                  label={<Dot error={!networkAllowed} />}
                  dropAlign={{ top: 'bottom', right: 'right' }}
                  dropContent={
                    <Box pad="small">
                      <Text>
                        <FormattedMessage
                          defaultMessage="Your wallet should be set to {executionLayerName} to use this launchpad."
                          values={{ executionLayerName }}
                        />
                      </Text>
                    </Box>
                  }
                />
              )}
              <Text size="small" className="ml10" color="greenLight">
                {trimString(account as string, 10)}
              </Text>
            </Box>
          )}
        </NavLinksRight>
      </TopBackground>
      <BottomBackground>
        <NavBarLinks>
          <Link to={routesEnum.landingPage} className="mx10 secondary-link">
            <BarLinkText
              level={4}
              margin="none"
              className="bar-link-text"
              active={pathname === routesEnum.landingPage}
            >
              <FormattedMessage defaultMessage="Overview" />
            </BarLinkText>
          </Link>
          <Link
            to={routesEnum.acknowledgementPage}
            className="mx10 secondary-link"
          >
            <BarLinkText
              level={4}
              margin="none"
              className="bar-link-text"
              active={pathname === routesEnum.acknowledgementPage}
            >
              <FormattedMessage defaultMessage="Deposit" />
            </BarLinkText>
          </Link>
          <Link to={routesEnum.withdrawals} className="mx10 secondary-link">
            <BarLinkText
              level={4}
              margin="none"
              className="bar-link-text"
              active={pathname === routesEnum.withdrawals}
            >
              <FormattedMessage defaultMessage="Withdrawal" />
            </BarLinkText>
          </Link>
          <ClientsDropdown
            className="secondary-link"
            label={
              <BarLinkText level={4} margin="none" active={isDropdownPage}>
                <FormattedMessage defaultMessage="Clients" />
              </BarLinkText>
            }
            dropAlign={{ top: 'bottom', right: 'right' }}
            dropContent={
              <BlackBox pad="medium">
                <Text className="my10">
                  <b>Execution clients</b>
                </Text>
                <Box pad="small">
                  <DropdownLink to={routesEnum.erigon}>
                    Erigon-Pulse
                  </DropdownLink>
                  <DropdownLink to={routesEnum.geth}>Go-Pulse</DropdownLink>
                </Box>
                <Text className="my10">
                  <b>Consensus clients</b>
                </Text>
                <Box pad="small">
                  <DropdownLink to={routesEnum.lighthouse}>
                    Lighthouse-Pulse
                  </DropdownLink>
                  <DropdownLink to={routesEnum.prysm}>Prysm-Pulse</DropdownLink>
                </Box>
              </BlackBox>
            }
          />
          <Link to={routesEnum.checklistPage} className="mx10 secondary-link">
            <BarLinkText
              level={4}
              margin="none"
              className="bar-link-text"
              active={pathname === routesEnum.checklistPage}
            >
              <FormattedMessage defaultMessage="Checklist" />
            </BarLinkText>
          </Link>
          <Link to={routesEnum.FaqPage} className="mx10 secondary-link">
            <BarLinkText
              level={4}
              margin="none"
              className="bar-link-text"
              active={pathname === routesEnum.FaqPage}
            >
              <FormattedMessage defaultMessage="FAQ" />
            </BarLinkText>
          </Link>
          <Link to={routesEnum.topUpPage} className="mx10 secondary-link">
            <BarLinkText
              level={4}
              margin="none"
              className="bar-link-text"
              active={pathname === routesEnum.topUpPage}
            >
              <FormattedMessage defaultMessage="Top Up" />
            </BarLinkText>
          </Link>
        </NavBarLinks>
      </BottomBackground>
    </React.Fragment>
  );
};

export const AppBar = withRouter(_AppBar);
