import axios from 'axios';
import { BEACONCHAIN_URL, ETH_DEPOSIT_OFFSET } from './envVars';

type BeaconchainResponse = {
  data: {
    status: string;
    data: {
      validatorscount: number;
      totalvalidatorbalance: string; // gwei
    };
  };
};

export const queryBeaconchain = async () => {
  try {
    const response: BeaconchainResponse = await axios.get(
      `${BEACONCHAIN_URL}/api/v1/epoch/latest`
    );
    console.log('data', response.data.data);

    // Convert the totalvalidatorbalance to a number and divide by 1e9
    const totalValidatorBalance = response.data.data.totalvalidatorbalance;
    const ethBalance = Number(
      `${totalValidatorBalance.slice(0, -9)}.${totalValidatorBalance.slice(-9)}`
    );

    return {
      statusCode: 200,
      body: {
        totalValidators: response.data.data.validatorscount,
        amountEth: Math.floor(ethBalance) + ETH_DEPOSIT_OFFSET,
      },
    };
  } catch (error) {
    console.error(error);
    return {
      statusCode: 500,
      body: {
        totalValidators: 0,
        amountEth: 0,
        msg:
          error instanceof Error ? error.message : 'An unknown error occurred',
      },
    };
  }
};
