import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import gethBg from '../../../static/geth-bg.png';
import {
  Hero,
  SectionTitle,
  ValidatorClientPageStyles,
} from '../ValidatorClientComponents';
import { PageTemplate } from '../../../pulsechain_components/PageTemplate';
import { Text } from '../../../pulsechain_components/Text';
import { Link } from '../../../pulsechain_components/Link';
import { Code } from '../../../pulsechain_components/Code';
import { Heading } from '../../../pulsechain_components/Heading';
import { IS_GOERLI } from '../../ConnectWallet/web3Utils';

import { IS_MAINNET } from '../../../utils/envVars';

// eslint-disable-next-line no-unused-vars
export const GethDetails = () => (
  <>
    <SectionTitle level={2} className="mb5">
      Go-Pulse
    </SectionTitle>
    <Text className="mt10">
      <FormattedMessage defaultMessage="One of the two original implementations of the PulseChain protocol." />
    </Text>
    <SectionTitle level={2} className="mb5">
      <FormattedMessage defaultMessage="Language information" />
    </SectionTitle>
    <Text className="mt10">
      <FormattedMessage defaultMessage="Go-Pulse is written in Go, fully open source and licensed under the GNU LGPL v3." />
    </Text>
    <section>
      <SectionTitle level={2} className="mb5">
        <FormattedMessage defaultMessage="Installation" />
      </SectionTitle>
      <Text>
        <FormattedMessage defaultMessage="The Go-Pulse documentation explains how to download and install the client." />
      </Text>
      <Link
        primary
        shouldOpenNewTab
        to="https://gitlab.com/pulsechaincom/go-pulse"
        className="mt10"
      >
        <FormattedMessage defaultMessage="Go-Pulse installation documentation" />
      </Link>
    </section>
    <section>
      <SectionTitle level={2} className="mb5">
        <FormattedMessage defaultMessage="Setup" />
      </SectionTitle>
      <Text className="mb20 mt10">
        <FormattedMessage defaultMessage="Make sure you do the following to get your execution client working properly." />
      </Text>
      {!IS_MAINNET && IS_GOERLI && (
        <>
          <Heading level={3} className="mt20">
            <FormattedMessage defaultMessage="Running Testnet" />
          </Heading>
          <Text className="mt10">
            <FormattedMessage
              defaultMessage="Use {testnet} to sync the PulseChain testnet."
              values={{
                testnet: <Code className="mt20">--pulsechain-testnet</Code>,
              }}
            />
          </Text>
          <Link
            primary
            shouldOpenNewTab
            to="https://gitlab.com/pulsechaincom/pulsechain-testnet"
          >
            <FormattedMessage defaultMessage="Go-Pulse Testnet documentation" />
          </Link>
        </>
      )}
      <Heading level={3} className="mt20">
        <FormattedMessage defaultMessage="JSON RPC endpoint" />
      </Heading>
      <Text className="mt10">
        <FormattedMessage
          defaultMessage="Use {http} to connect your consensus node to the JSON RPC
          endpoint. This will enable the JSON RPC services on the default 8545
          port."
          values={{
            http: <Code className="mt20">--http</Code>,
          }}
          description="{http} shows '--http' terminal command"
        />
      </Text>
      <Link
        primary
        shouldOpenNewTab
        to="https://gitlab.com/pulsechaincom/pulsechain-testnet"
      >
        <FormattedMessage defaultMessage="--http documentation" />
      </Link>
    </section>
  </>
);

export const Geth = () => {
  const { formatMessage } = useIntl();
  return (
    <PageTemplate
      title={formatMessage(
        { defaultMessage: 'Execution Clients: {clientName}' },
        { clientName: 'Go-Pulse' }
      )}
    >
      <ValidatorClientPageStyles>
        <Hero imgSrc={gethBg} />
        <GethDetails />
        <section>
          <SectionTitle level={2} className="mb5">
            <FormattedMessage defaultMessage="Documentation" />
          </SectionTitle>
          <Link
            shouldOpenNewTab
            primary
            to="https://gitlab.com/pulsechaincom/go-pulse"
          >
            <FormattedMessage defaultMessage="Documentation on running Go-Pulse" />
          </Link>
        </section>
      </ValidatorClientPageStyles>
    </PageTemplate>
  );
};
